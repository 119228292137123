<template>
  <div class="mt-4">
    <v-row
        class="file-list-container"
       ref="file-list-container">
      <v-col
          v-for="(item,index) in items"
          :key="index"
          :cols="12">
        <v-card
            class="file-card"
            link
            :to="`/files/${item.id}`"
            target="_blank"
        >

          <v-card-title class="d-flex align-start justify-start flex-column">
            <div class="font-weight-bold text-body-1 black--text mb-2">
              {{ item.title }}
            </div>
            <v-img
                lazy-src="/img/placeholder.png"
                class="rounded-lg mb-2"
                width="100%"
                max-height="150"
                :src="item.image"/>
          </v-card-title>
          <v-card-text>
            <v-row type="flex">
              <v-col :cols="12">
                <div class="font-weight-bold text-body-1 d-flex align-center justify-start">
                  <v-icon>
                    mdi-map-marker
                  </v-icon>
                  {{ item.address }}
                </div>
                <v-row no-gutters>
                  <v-col :cols="12">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        قیمت کل / رهن :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.totalPrice }}
                        تومان
                      </div>
                    </div>
                  </v-col>
                  <v-col :cols="12">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        قیمت متری / اجاره :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.pricePerMeter }}
                        تومان
                      </div>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        طبقه :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.floor }}
                      </div>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        متراژ :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.baseArea }}
                        متر
                      </div>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        سن بنا :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.age }}
                      </div>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div class="d-flex align-center justify-start">
                      <div class="file-attribute-name">
                        <v-icon size="10">
                          mdi-moon-full
                        </v-icon>
                        تعداد خواب :
                      </div>
                      <div class="font-weight-bold text-body-1 mr-2 file-attribute-value">
                        {{ item.sleepRoom }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex align-center justify-center mt-3">
                  <v-chip
                      v-if="item.hasElevator"
                      class="ml-1"
                      small
                      text-color="white"
                      color="main">
                    آسانسور
                  </v-chip>

                  <v-chip
                      v-if="item.hasWarehouse"
                      class="ml-1"
                      small
                      text-color="white"
                      color="main">
                    انباری
                  </v-chip>

                  <v-chip
                      v-if="item.hasParking"
                      class="ml-1"
                      small
                      text-color="white"
                      color="main">
                    پارکینگ
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <div class="file-date">
            {{ item.date }}
          </div>

          <v-card-actions class="d-flex align-center justify-space-between">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click.prevent="$emit('addBookmark',item)"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    icon
                    fab
                    small
                    color="main">
                  <v-icon v-if="item.bookmarked">
                    mdi-bookmark
                  </v-icon>
                  <v-icon v-else>
                    mdi-bookmark-outline
                  </v-icon>
                </v-btn>
              </template>
              ذخیره
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    :loading="item.printLoading"
                    @click.prevent="$emit('printFile',item)"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    icon
                    fab
                    color="main">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              چاپ فایل
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    icon
                    fab
                    color="main">
                  <v-icon>
                    mdi-share-variant-outline
                  </v-icon>
                </v-btn>
              </template>
              اشتراک گذاری فایل
            </v-tooltip>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>


export default {
  name: "FileListRowResponsive",

  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  mounted() {
    this.$refs['file-list-container'].addEventListener('scroll', (e) => {

      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;

      console.log(scrollTop, clientHeight, scrollHeight);

      if (scrollTop + clientHeight + 30 >= scrollHeight) {
        this.$emit('onScrollEnded');
      }
    })
  },


}
</script>

<style scoped>
.file-card {
  position: relative;
  overflow: hidden;
}

.file-attribute-name {
  font-size: 13px;
  color: #333;
}

.file-attribute-value {
  color: #000;
  font-size: 13px;
}

.file-date {
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px 12px;
  font-size: 12px;
  background-color: #007bec;
  color: #fff;
  border-bottom-right-radius: 8px;
}

.file-actions {
  position: absolute;
  bottom: 10px;
  right: -200px;
  transition: 0.5s;
  height: max-content;
}

.file-card:hover .file-actions {
  right: 20px;
  transition: 0.5s;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.file-list-container {
  height: calc(100vh - 140px);
  overflow: auto;
}
</style>